import { useEffect } from "react";

const GTM = ({ containerId }) =>
  // 
  {
    useEffect(() => {
      const handleRouteChange = () => {
        const script = document.createElement("script");
        script.innerHTML = `
        (function() {
          const urlParams = new URLSearchParams(window.location.search);
          const utmParams = {
            utmSource: urlParams.get('utm_source'),
            utmMedium: urlParams.get('utm_medium'),
            utmCampaign: urlParams.get('utm_campaign'),
            utmTerm: urlParams.get('utm_term'),
            utmContent: urlParams.get('utm_content'),
          };

          Object.entries(utmParams).forEach(([key, value]) => {
            if (value) {
              gtag('event', 'UTM Parameter', {
                event_category: 'UTM',
                event_label: key,
                event_value: value,
              });
            }
          });
        })();
      `;

        document.head.appendChild(script);
      };

      handleRouteChange();
    }, []);

    return null;
  };

export default GTM;
