import axios from "axios";
import { create } from "zustand";
import convertArrToObj from "../ArrToObj";
// import { persist } from 'zustand/middleware'
// import { combine } from 'zustand/middleware'
//import RingVariations from '@/ringSections/RingVariations';

export type zustandStore = {
  productFilters: { brand: string[] };
  setProductFilters: (x: string) => void;
  removeProductFilter: (x: string) => void;
  navBurgerState: boolean;
  setNavBurgerState: () => void;
  closeNavBurger: () => void;
  user: { email: string };
  setUser: (x: string) => void;

  cart: any;
  address: string;
  fetchCart: () => void;
  setCart: (x: any, y: any) => void;
  setCartAddress: () => void;
  products: { list: any[] };
  setProducts: (x: any) => void;
  searchText: { text: string };
  setSearchText: (x: string) => void;
  salesQuotation: any;
  setSalesQuotation: () => void;
  currentPage: number;
  setCurrentPage: (x: number) => void;
  ringVariant: any;
  setRingVariant: (x: string) => void;
  loadingAddRingToCart: boolean;
  setLoadingAddRingToCart: (x: boolean) => void;
  ringBtnPopUpOn: { orderType: ""; booleanState: false };
  setRingBtnPopUpOn: (x: any) => void;
  redemptionFactor: string;
  setRedemptionFactor: (newFactor: string) => void;
  loyaltyPoints: number | string;
  setLoyaltyPoints: (newPoints: number | string) => void;
  sliderValue: number;
  setSliderValue: (newValue: number) => void;
  loyaltyAmount: number;
  setLoyaltyAmount: (newLoyaltyAmount: number) => void;
  globalModal: boolean;
  toggleGlobalModal: () => void;
};

const useZustandStore = create<zustandStore>((set) => ({
  globalModal: false,
  toggleGlobalModal: () =>
    set((state) => {
      return { globalModal: !state.globalModal };
    }),
  productFilters: { brand: [] },
  setProductFilters: (x) =>
    set((state) => {
      return { productFilters: { brand: [...state.productFilters.brand, x] } };
    }),
  removeProductFilter: (x) =>
    set((state) => {
      const currentFilters = state.productFilters.brand;
      const filtered = currentFilters.filter((el) => el !== x);
      return { productFilters: { brand: filtered } };
    }),
  navBurgerState: false,
  closeNavBurger: () =>
    set(() => {
      return { navBurgerState: false };
    }),
  setNavBurgerState: () =>
    set((state) => {
      return { navBurgerState: !state.navBurgerState };
    }),
  user: { email: "" },
  setUser: (x) =>
    set(() => {
      return { user: { email: x } };
    }),
  cart: {},
  address: "",

  fetchAddress: async () => {},

  fetchCart: async () => {
    const res = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL}/api/method/erpnext.e_commerce.shopping_cart.cart.get_cart_quotation`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        withCredentials: true,
      }
    );
    // const itemsArr = res.data.message.doc.items.map((el:any)=>{return {item_code:el.item_code,amount:el.amount,qty:el.qty}})
    const cartObj = await convertArrToObj(res.data.message.doc.items);
    set({ cart: cartObj });
  },

  setCart: (x, y) =>
    set((state) => {
      y();
      return { cart: { ...state.cart, ...x } };
    }),
  setCartAddress: async () => {
    const res = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL}/api/method/erpnext.e_commerce.shopping_cart.cart.get_cart_quotation`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        withCredentials: true,
      }
    );
    // const itemsArr = res.data.message.doc.items.map((el:any)=>{return {item_code:el.item_code,amount:el.amount,qty:el.qty}})
    const address = res.data.message.doc.shipping_address;
    set({ address: address });
  },
  products: { list: [] },
  setProducts: (x) =>
    set(() => {
      return { products: { list: x } };
    }),
  searchText: { text: "" },
  setSearchText: (x) =>
    set(() => {
      return { searchText: { text: x } };
    }),
  salesQuotation: "",
  setSalesQuotation: async () => {
    const res = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL}/api/method/erpnext.e_commerce.shopping_cart.cart.get_cart_quotation`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        withCredentials: true,
      }
    );

    set({ salesQuotation: res.data.message });
  },
  currentPage: 0,
  setCurrentPage(x: number) {
    set({ currentPage: x });
  },
  ringVariant: null,
  setRingVariant: (x: string) =>
    set(() => {
      return { ringVariant: x };
    }),
  loadingAddRingToCart: false,
  setLoadingAddRingToCart: (x: boolean) =>
    set(() => {
      return { loadingAddRingToCart: x };
    }),
  ringBtnPopUpOn: { orderType: "", booleanState: false },
  setRingBtnPopUpOn: (x: any) =>
    set(() => {
      return { ringBtnPopUpOn: x };
    }),
  redemptionFactor: "",
  setRedemptionFactor: (newFactor) => set({ redemptionFactor: newFactor }),
  loyaltyPoints: "",
  setLoyaltyPoints: (newPoints) => set({ loyaltyPoints: newPoints }),
  sliderValue: 0,
  setSliderValue: (newValue) => set({ sliderValue: newValue }),
  loyaltyAmount: 0,
  setLoyaltyAmount: (newLoyaltyAmount) =>
    set({ loyaltyAmount: newLoyaltyAmount < 1 ? 1 : newLoyaltyAmount }),
}));

export default useZustandStore;

// const ringVariants = {
//   blackEB: 'Bonatra X1 Ring EB-BLA',
//   goldEB: 'Bonatra X1 Ring EB-GOL',
//   silverEB: 'Bonatra X1 Ring EB-SIL',
//   roseGoldEB: 'Bonatra X1 Ring EB-ROG',
//   roseGold: 'Bonatra X1 Ring Preorder-ROGx',
//   gold: 'Bonatra X1 Ring Preorder-GOL',
//   black: 'Bonatra X1 Ring Preorder-BLA',
//   silver: 'Bonatra X1 Ring Preorder-SVR'
// }
export const ringVariations = {
  black: {
    style: "black",
    item_color_name: "Noir Black",
    item_code_eb: "Bonatra X1 Ring EB-BLA",
    item_code: "Bonatra X1 Ring Preorder-BLA",
    color_code: "#000",
  },
  silver: {
    style: "silver",
    item_color_name: "Stardust Silver",
    item_code_eb: "Bonatra X1 Ring EB-SVR",
    item_code: "Bonatra X1 Ring Preorder-SVR",
    color_code: "#C9C9C9",
  },
  roseGold: {
    style: "roseGold",
    item_color_name: "Sunrise Rose Gold",
    item_code_eb: "Bonatra X1 Ring EB-ROG",
    item_code: "Bonatra X1 Ring Preorder-ROG",
    color_code: "#B76E79",
  },
  gold: {
    style: "gold",
    item_color_name: "Sunbeam Gold",
    item_code_eb: "Bonatra X1 Ring EB-GOL",
    item_code: "Bonatra X1 Ring Preorder-GOL",
    color_code: "#DFC160",
  },
};

export const ringVariationType = typeof ringVariations.black;
