import { useMutation } from "@tanstack/react-query";
import axios from "axios";

const useLoginWithOneTap = () => {
    const URL = `${process.env.NEXT_PUBLIC_API_URL}/api/method/bonatra.www.login.decode_google_jwt`;
    type LoginWithOneTapPayloadType = {
        client_id: string | undefined;
        credential: string | undefined;
        select_by: "auto" | "user" | "user_1tap" | "user_2tap" | "btn" | "btn_confirm" | "brn_add_session" | "btn_confirm_add_session" | undefined;
        use_jwt: number;
    }

    return useMutation(async ({payload}: {payload: LoginWithOneTapPayloadType}) => {
        try {
            const res = await axios({
                method: 'post',
                url: URL,
                headers: { 
                    'Content-Type': 'application/json', 
                },
                data : payload
            })
            return res
        } catch(error) {
            console.log("useLoginWithOneTap error: ", error)
        }
    })
}

export default useLoginWithOneTap;