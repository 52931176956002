"use client";
import Section from "@/lib/Layout/Wrappers/Section";
import Image from "next/image";
import React from "react";
import BonatraLogoFull from "@/public/logoFull.png";
import Heading3 from "@/lib/Layout/Wrappers/Heading3";
import Paragraph from "@/lib/Layout/Wrappers/Paragraph";
import { MdLocationOn, MdMail, MdSmartphone } from "react-icons/md";
import { usePathname, useRouter } from "next/navigation";
import { cn } from "@/lib/utils";
import Link from "next/link";
const FooterHealthSpan = () => {
  const pathName = usePathname();

  return (
    <div
      className={cn(
        pathName === "/coach-consultation" ? "bg-white" : "bg-[#141416]"
      )}
    >
      <footer className="bg-black pt-12 rounded-t-[4rem]">
        <Section className="flex flex-col md:flex-row justify-between gap-8">
          <div className="flex flex-col gap-8">
            {/* {pathName === "/testss" ? (
              <Image
                src="https://assets.bonatra.com/Website%20Assets/White.webp"
                height={200}
                width={200}
                alt="logo"
              />
            ) : (
              <Image src={BonatraLogoFull} alt="logo" />
            )} */}
            <Image
              src="https://assets.bonatra.com/Website%20Assets/White.webp"
              height={200}
              width={200}
              alt="logo"
            />
            <FooterSocialLogos />
            <ReachUsOn />
            <FooterGst />
          </div>
          <ul className="md:grid grid-cols-3 gap-8 sm:flex sm:flex-wrap ">
            {footerDatas?.map((el, idx) => (
              <FooterLinks key={idx} footerLabelData={el} />
            ))}
          </ul>
        </Section>
      </footer>
    </div>
  );
};
const FooterGst = () => {
  return (
    <div className="text-white mt-6">
      <p>CIN: U85300KA2021PTC153931</p>
      <p>GSTIN: 29AAKCB2775Q1ZS</p>
    </div>
  );
};
const FooterSocialLogos = () => {
  return (
    <>
      <ul className="flex gap-4 mt-8">
        {footerSocialLogoSrc.map((logo) => (
          <li key={logo.icon}>
            <a href={logo.href} title={logo.name} target="_blank">
              <Image
                src={logo.icon}
                alt={logo.name}
                height={30}
                width={30}
              ></Image>
            </a>
          </li>
        ))}
      </ul>
    </>
  );
};
const footerSocialLogoSrc = [
  // { href: "", name: "whatsapp", icon: "https://assets.bonatra.com/package_detail/Whatsappicon.webp" },
  {
    href: "https://www.facebook.com/bonatra.health",
    name: "facebook",
    icon: "https://assets.bonatra.com/package_detail/facebook-1.webp",
  },
  {
    href: "https://instagram.com/bonatra.health?igshid=YmMyMTA2M2Y=",
    name: "instagram",
    icon: "https://assets.bonatra.com/package_detail/instagram-1.webp",
  },
  {
    href: "https://www.linkedin.com/company/76988968/admin/",
    name: "linkedin",
    icon: "https://assets.bonatra.com/package_detail/Linked%20in.webp",
  },
  {
    href: "https://play.google.com/store/apps/details?id=com.yoihealth",
    name: "playstore icon",
    icon: "https://assets.bonatra.com/icons/google-play.webp",
  },
  {
    href: "https://apps.apple.com/in/app/bonatra/id1600079061",
    name: "appstore icon",
    icon: "https://assets.bonatra.com/icons/app-store.webp",
  },
];

const ReachUsOn = () => {
  const pathName = usePathname();
  return (
    <article className="flex flex-col gap-2 ">
      <Heading3 classname="text-[#22C55E]">Contact us:</Heading3>
      <Paragraph className="text-white flex gap-2 items-start">
        <MdLocationOn size={25} color="#fff" />
        1848/C, 14th Cross, 21st Main Rd, Vanganahalli,
        <br /> 1st Sector, HSR Layout,
        <br /> Bengaluru, Karnataka 560102
      </Paragraph>
      <Paragraph className="text-white flex gap-2 items-center">
        <MdSmartphone size={20} color="#fff" />
        <a href="tel:+91 8095023777">+91 8095023777</a>
      </Paragraph>
      <Paragraph className="text-white flex gap-2 items-center">
        <MdMail size={20} color="#fff" />
        <a href="mailto:livelonger@bonatra.com"> livelonger@bonatra.com</a>
      </Paragraph>
    </article>
  );
};
const FooterLinks = ({ footerLabelData }) => {
  const pathName = usePathname();
  const router = useRouter();
  const handleClick = (el, i) => {
    router.push(`${footerLabelData?.route[i]}`);
  };
  return (
    <li className="mb-4">
      <span className="font-semibold mb-4 text-2xl text-[#22C55E]">
        {footerLabelData.title}
      </span>
      <ul className="flex flex-col justify-start items-start content-start ">
        {footerLabelData.labels.map((el, index) => {
          return (
            <a
              key={index}
              className=" mb-2  text-left"
              onClick={() => handleClick(el, index)}
            >
              <li key={el} className="text-white cursor-pointer ">
                {el}
              </li>
            </a>
          );
        })}
      </ul>
    </li>
  );
};

export default FooterHealthSpan;
export const footerDatas = [
  {
    title: "Quick Links",
    labels: [
      "Home",
      "All Programs & Products",
      "What is a CGM ?",
      "Blog",
      "About Us",
      "Login",
      "Signup",
      "How to Setup Ring ?",
    ],
    route: [
      "/",
      "/all-products",
      "/continuous-glucose-monitoring",
      "https://blog.thehealthspan.ai/",
      "/about-us",
      "/login",
      "/signup",
      "/bonatra-x1-ring-faq",
    ],
    queryid: [""],
  },
  {
    title: "Bonatra x programs",
    labels: ["Age Reversal", "Metabolic Health", "Weight Loss"],
    route: [
      "/package/age-reversal",
      "/package/metabolic-health",
      "/package/weight-loss",
    ],
    queryid: [""],
  },
  {
    title: "All Programs & Products",
    labels: [
      "Smart Ring X1",
      "Bonatra Programs",
      "Bonatra Supplements",
      "Referral Program",
      "Return & Refund Policy",
    ],
    route: [
      "/bonatra-ring-x1",
      "/all-products/reversal-program",
      "/all-products/supplements",
      "/referral-program",
      "/return-refund-policy",
    ],
    queryid: [""],
  },
  {
    title: "LEGAL",
    labels: ["Privacy Policy"],
    route: ["/tnc"],
    queryid: [""],
  },
  // {
  //   title: "Browse Programs",
  //   labels: ["Bonatra Diabetes-Reversal Program", "Bonatra Fitness Program"],
  //   route: ["/programs/diabetes-reversal", "/programs/fitness"],
  //   queryid: [""],
  // },
  {
    title: "FAQs",
    labels: ["Bonatra X1 Ring FAQs", "Bonatra Diabetes-Reversal Program FAQs"],
    route: ["/bonatra-x1-ring-faq2", "bonatra-diabetes-reversal-program-faq"],
    queryid: [""],
  },
];
