import useGetAllCartItems from "./QueryHooks/useGetAllCartItems"

const useCartCount = () => {
    const { data: allCartItems, isLoading } = useGetAllCartItems()
    let cartQty: number | null | undefined = null

    if (isLoading) return { cartQty, isLoading }
    cartQty = allCartItems?.items.length

    return {
        cartQty,
        isLoading
    } as {
        cartQty: number | null | undefined,
        isLoading: boolean
    }
}
export default useCartCount
