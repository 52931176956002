import { FC, useEffect } from "react";
import Head from "next/head";
import Image from "next/image";

interface IFacebookPixelProps {
  pixelId: string[];
}

const FacebookPixel: FC<IFacebookPixelProps> = ({ pixelId }) => {

  const script = pixelId
    ?.map(
      (id) => `
    !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '${id}');
                fbq('track', 'PageView');
  `
    )
    .join("");

  useEffect(() => {
    console.log("Generated Facebook Pixel Script:", script);
    if (typeof window !== "undefined" && script) {
      const scriptElement = document.createElement("script");
      scriptElement.innerHTML = script;
      document.head.appendChild(scriptElement);
    }
  }, [script]);

  const facebookPixelIdMap = pixelId.map((id) => (
    <Image
      key={id}
      alt="facebook-pixel"
      height="1"
      width="1"
      style={{ display: "none" }}
      src={`https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1`}
    />
  ))
  

  return (
    <>
      <Head>
        <noscript>
          {facebookPixelIdMap}
        </noscript>
      </Head>
    </>
  );
};

export default FacebookPixel;
// // FacebookPixel.tsx

// import { FC } from "react";
// import Head from "next/head";
// import FacebookPixelScript from "./FacebookPixelScript";
// import Image from "next/image";

// interface FacebookPixelProps {
//   pixelId: string[];
// }

// const FacebookPixel: FC<FacebookPixelProps> = ({ pixelId }) => {
//   const facebookPixelIdMap = pixelId?.map((id) => (
//     <Image
//       key={id}
//       alt="facebook-pixel"
//       height="1"
//       width="1"
//       style={{ display: "none" }}
//       src={`https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1`}
//     />
//   ));

//   return (
//     <>
//       <Head>
//         <FacebookPixelScript pixelId={pixelId[0]} />
//         <noscript>{facebookPixelIdMap}</noscript>
//       </Head>
//     </>
//   );
// };

// export default FacebookPixel;
