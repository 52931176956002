"use client";

import { Suspense, useEffect } from "react";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "@/lib/theme";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { usePathname, useSearchParams } from "next/navigation";
import FacebookPixel from "../components/FbPixel/FacebookPixel";
import GTM from "../pages/gtm/GTM";
import { initGA, logPageView } from "../analytics";
import "./globals.css";
import { FaWhatsapp } from "react-icons/fa";
import { Urbanist } from "next/font/google";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

type ClientLayoutProps = {
  children: React.ReactNode;
};

const ClientLayout = ({ children }: ClientLayoutProps) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const isNotCartPage = pathname !== "/cart";
  const isNotWebView = searchParams?.get("webview") !== "true";
  const isNotBioForm = pathname !== "/biologicalAgeCalculator";
  const pixelIds = ["1738933153276643", "221052926844600"];

  useEffect(() => {
    if (!(window as any).GA_INITIALIZED) {
      initGA();
      (window as any).GA_INITIALIZED = true;
    }
    logPageView();
  }, []);

  if (searchParams === null) {
    return <div>Loading...</div>;
  }

  return (
    <main>
      <Suspense>
        <QueryClientProvider client={queryClient}>
          <GoogleOAuthProvider
            clientId={
              process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID ||
              "74216840596-v7t2soa1neu4amv6aohgu198096bcap2.apps.googleusercontent.com"
            }
          >
            <ReactQueryDevtools initialIsOpen={false} />
            <ChakraProvider theme={theme}>
              {isNotCartPage && isNotWebView && isNotBioForm && (
                <div className="w-[55%] md:w-[40%] lg:w-[20%] p-2 bg-gray-800 fixed bottom-20 md:bottom-10 right-2 md:right-10 z-50 rounded-lg text-sm mr-4">
                  <a
                    href="https://wa.me/+919108713102"
                    title="whatsapp-logo"
                    target="_blank"
                    className="flex flex-row justify-center items-center"
                  >
                    <span className="text-white text-sm md:text-lg font-bold mb-2 mr-3">
                      Talk to our expert
                    </span>
                    <FaWhatsapp className="ml-3 text-white text-5xl bg-green-500 rounded-full p-3 hover:bg-green-600 cursor-pointer animate-pulse" />
                  </a>
                </div>
              )}
              <FacebookPixel pixelId={pixelIds} />
              <GTM containerId="GTM-TJHFQ7S" />
              {/* Include scripts and links */}
              <script
                type="text/javascript"
                dangerouslySetInnerHTML={{
                  __html: `(function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
              })(window, document, "clarity", "script", "hik37q582s");`,
                }}
              />
              <link rel="preconnect" href="https://fonts.googleapis.com" />
              <link rel="preconnect" href="https://fonts.gstatic.com" />
              <link
                href="https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap"
                rel="stylesheet"
              />
              {/* Include Google Tag Manager */}
              <noscript>
                <iframe
                  src="https://www.googletagmanager.com/ns.html?id=GTM-TJHFQ7S"
                  height="0"
                  width="0"
                  style={{ display: "none", visibility: "hidden" }}
                ></iframe>
              </noscript>
              {children}
            </ChakraProvider>
          </GoogleOAuthProvider>
        </QueryClientProvider>
      </Suspense>
    </main>
  );
};

export default ClientLayout;
