import { cn } from "@/lib/utils";
import React, { ReactNode } from "react";

const Section = ({
  children,
  className,
  ...props
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <section
      {...props}
      className={cn("max-w-[80rem] m-auto px-8 lg:px-0", className)}
    >
      {children}
    </section>
  );
};

export default Section;
