import React, { ReactNode } from "react";

const Paragraph = ({
  className = "",
  ...props
}: {
  children: ReactNode;
  className?: string;
}) => {
  return <p className={` ${className} `}>{props.children}</p>;
};

export default Paragraph;
