"use client";
import Section from "@/components/new-components/wrapper/Section";
import useCheckLogin from "@/lib/hooks/useCheckLogin";
import useLogout from "@/lib/hooks/AuthHooks/useLogout";
import useNavStore from "@/lib/store/newStore/useNavStore";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { useRouter } from "next/navigation";
const NavMenu = () => {
  const [isLoggedin, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);
  }, []);
  const router = useRouter();
  const { toast } = useToast();
  const navControl = useNavStore((state) => state.setBurgerState);
  // const { data: isLoggedin } = useCheckLogin();
  const { mutate: logoutMutate } = useLogout();
  const handleLogut = () => {
    localStorage.removeItem("token");
    logoutMutate("dummy", {
      onSuccess: () => {
        toast({
          title: "Logged out successfully",
          duration: 2000,
          variant: "successGreen",
        });
        window?.location?.reload();
      },
      onError: (error) => {
        console.log("error: ", error);
      },
    });
  };
  const handleLogin = () => {
    navControl();
    router.push("/login");
  };
  const linkMap = navlinksMap.map((item, i) => {
    return (
      <li key={i} className="flex flex-col  items-end ">
        <li className="text-right md:text-lg  ">
          <Link href={item.link} className=" " onClick={navControl}>
            {item.name}
          </Link>
        </li>

        {i === navlinksMap.length - 1 && isLoggedin && (
          <Button className="mt-3 w-36" onClick={handleLogut}>
            Logout
          </Button>
        )}
      </li>
    );
  });
  return (
    <Section className="flex flex-1 mt-6  justify-end flex-col items-end">
      <ul className="text-lg flex flex-col gap-5 font-semibold  lg:grid lg:grid-cols-1 lg:col-gap-96 left-3.5 ">
        {linkMap}
      </ul>
      {!isLoggedin && (
        <div className="flex justify-end  w-full lg:hidden">
          <Button className="w-36  mt-5 bg" onClick={handleLogin}>
            Login
          </Button>
        </div>
      )}
    </Section>
  );
};

export default NavMenu;
const navlinksMap = [
  {
    name: "Home",
    link: "/",
    queryId: "",
  },
  {
    name: "Bonatra Ring X1",
    link: "/bonatra-ring-x1",
    queryId: "",
  },
  {
    name: "Bonatra Team",
    link: "/bonatra-team",
    queryId: "",
  },
  {
    name: "Store",
    link: "/all-products",
    queryId: "",
  },
  {
    name: "About",
    link: "/about-us",
    queryId: "",
  },
  {
    name: "Contact",
    link: "/contact",
    queryId: "",
  },
  {
    name: "Privacy Policy",
    link: "/tnc",
    queryId: "",
  },
  {
    name: "Blogs",
    link: "https://blog.thehealthspan.ai/",
    queryId: "",
  },
  // {
  //   name: "Bonatra X Diabetes",
  //   link: "/package/one-month",
  //   queryId: "",
  // },
  // {
  //   name: "Bonatra X Fitness",
  //   link: "/package/fitness",
  //   queryId: "",
  // },
  {
    name: "Ring Sizer",
    link: "/bonatra-ring-x1-sizer/SizerHome",
    queryId: "",
  },
  {
    name: "Referral Program",
    link: "/referral-program",
    queryId: "",
  },
];
