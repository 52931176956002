'use client'
import { ThemeProvider } from "next-themes";
import { ReactNode } from "react";

interface PageProviderProps {
    children: ReactNode;
  }

const DarkThemeProvider = ({ children }: PageProviderProps) => {
    return <ThemeProvider attribute="class">{children}</ThemeProvider>;
}

export default DarkThemeProvider;