import axios from "axios";
import { useQuery } from "@tanstack/react-query";
export type cartItemType = typeof sampleCartItems;

const useGetAllCartItems = () => {
  return useQuery<cartItemType>(
    ["getAllCartItems"],
    async () => {
      const res = await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}/api/method/erpnext.e_commerce.shopping_cart.cart.get_cart_quotation`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          withCredentials: true,
        }
      );
      return res.data.message.doc;
    },
    { cacheTime: 1000 * 60 * 60 * 24 }
  );
};

export default useGetAllCartItems;

const sampleCartItems = {
  name: "SAL-QTN-2023-00841",
  owner: "archit@bonatra.com",
  creation: "2023-03-27 17:46:55.782828",
  modified: "2023-03-29 13:11:56.126477",
  modified_by: "archit@bonatra.com",
  docstatus: 0,
  idx: 1,
  title: "Archit Gupta",
  naming_series: "SAL-QTN-.YYYY.-",
  quotation_to: "Customer",
  party_name: "Archit Gupta - 7",
  customer_name: "Archit Gupta",
  is_reverse_charge: 0,
  is_export_with_gst: 0,
  transaction_date: "2023-03-27",
  order_type: "Shopping Cart",
  company: "Bonatra HealthCare",
  currency: "INR",
  conversion_rate: 1,
  selling_price_list: "Standard Selling",
  price_list_currency: "INR",
  plc_conversion_rate: 1,
  ignore_pricing_rule: 0,
  total_qty: 1,
  total_net_weight: 0,
  base_total: 2499,
  base_net_total: 2499,
  total: 2499,
  net_total: 2499,
  tax_category: "",
  taxes_and_charges: "Output GST In-state - BH",
  base_total_taxes_and_charges: 0,
  total_taxes_and_charges: 0,
  base_grand_total: 2499,
  base_rounding_adjustment: 0,
  base_rounded_total: 0,
  base_in_words: "INR Two Thousand, Four Hundred And Ninety Nine only.",
  grand_total: 2499,
  rounding_adjustment: 0,
  rounded_total: 0,
  in_words: "INR Two Thousand, Four Hundred And Ninety Nine only.",
  apply_discount_on: "Grand Total",
  base_discount_amount: 0,
  additional_discount_percentage: 0,
  discount_amount: 0,
  customer_address: "Land Capital Primero-Shipping-1",
  address_display:
    "A005, Land Capital Primero<br>Off Sarjapur Road, Carmelaram<br>Bangalore<br>\nKarnataka<br>560035<br>India<br>\nPhone: 7400269982<br>",
  gst_category: "Unregistered",
  place_of_supply: "29-Karnataka",
  contact_person: "Archit Gupta-4-Archit Gupta - 7",
  contact_display: "Archit Gupta",
  contact_mobile: "",
  contact_email: "archit@bonatra.com",
  shipping_address_name: "Land Capital Primero-Shipping-1",
  shipping_address:
    "A005, Land Capital Primero<br>Off Sarjapur Road, Carmelaram<br>Bangalore<br>\nKarnataka<br>560035<br>India<br>\nPhone: 7400269982<br>",
  company_address: "Bonnatra Office-Billing",
  company_gstin: "29AAKCB2775Q1ZS",
  company_address_display:
    "1848/C, 14th Cross, 21st Main, Vanganahalli, 1st Sector, HSR Layout<br>Bangalore<br>\nKarnataka<br>560102<br>India<br>\nEmail: livelonger@bonatra.com<br>",
  group_same_items: 0,
  language: "en",
  status: "Draft",
  customer_group: "All Customer Groups",
  territory: "All Territories",
  doctype: "Quotation",
  pricing_rules: [
    {
      name: "3c2c16756b",
      creation: "2023-03-29 13:11:56.571645",
      modified: "2023-03-29 13:11:56.571645",
      modified_by: "archit@bonatra.com",
      docstatus: 0,
      idx: 1,
      pricing_rule: "PRLE-0004",
      item_code: "Bonatra X  Diabetes",
      child_docname: "5e38bc6495",
      rule_applied: 1,
      parent: "SAL-QTN-2023-00841",
      parentfield: "pricing_rules",
      parenttype: "Quotation",
      doctype: "Pricing Rule Detail",
    },
  ],
  payment_schedule: [
    {
      name: "8f15febd0e",
      creation: "2023-03-29 13:11:56.577503",
      modified: "2023-03-29 13:11:56.577503",
      modified_by: "archit@bonatra.com",
      docstatus: 0,
      idx: 1,
      due_date: "2023-03-27",
      invoice_portion: 100,
      discount: 0,
      payment_amount: 2499,
      outstanding: 2499,
      paid_amount: 0,
      discounted_amount: 0,
      base_payment_amount: 2499,
      parent: "SAL-QTN-2023-00841",
      parentfield: "payment_schedule",
      parenttype: "Quotation",
      doctype: "Payment Schedule",
    },
  ],
  taxes: [],
  packed_items: [],
  lost_reasons: [],
  competitors: [],
  items: [
    {
      name: "5e38bc6495",
      owner: "archit@bonatra.com",
      creation: "2023-03-27 17:46:55.782828",
      modified: "2023-03-29 13:11:56.126477",
      modified_by: "archit@bonatra.com",
      docstatus: 0,
      idx: 1,
      item_code: "Bonatra X  Diabetes",
      item_name: "Bonatra X  Diabetes",
      description:
        "<div><p>Bonatra X- Diabetes Reversal program is a doctor-led, technology-supported, and health coach-guided program. The CGM digitises your body, and our AI-based app creates 100% personalised programs which are doctor verified. The health coach will handhold you to ensure visible results in the first 14 days.</p></div>",
      gst_hsn_code: "999321",
      is_nil_exempt: 0,
      is_non_gst: 0,
      item_group: "CGM Program with Doctor consultations",
      brand: "Bonatra X",
      image: "",
      qty: 1,
      stock_uom: "Nos",
      uom: "Nos",
      conversion_factor: 1,
      stock_qty: 1,
      price_list_rate: 4998,
      base_price_list_rate: 4998,
      margin_rate_or_amount: 0,
      rate_with_margin: 0,
      discount_percentage: 50,
      discount_amount: 2499,
      base_rate_with_margin: 0,
      rate: 2499,
      net_rate: 2499,
      amount: 2499,
      net_amount: 2499,
      base_rate: 2499,
      base_net_rate: 2499,
      base_amount: 2499,
      base_net_amount: 2499,
      pricing_rules: '[\n "PRLE-0004"\n]',
      stock_uom_rate: 2499,
      is_free_item: 0,
      valuation_rate: 0,
      gross_profit: 2499,
      weight_per_unit: 0,
      total_weight: 0,
      warehouse: "Stores - BH",
      against_blanket_order: 0,
      blanket_order_rate: 0,
      projected_qty: -212,
      actual_qty: 0,
      item_tax_rate: "{}",
      page_break: 0,
      parent: "SAL-QTN-2023-00841",
      parentfield: "items",
      parenttype: "Quotation",
      doctype: "Quotation Item",
    },
  ],
};
