const convertArrToObj = async (arr: any[]) => {
  const res = arr.reduce((acc, curr) => {
    acc[curr.item_code] = { price: curr.amount, quantity: curr.qty };
    return acc;
  }, {});
  return res
}

export default convertArrToObj

export const convertArrToObjNonAsync = (arr: any[]) => {
  const res = arr?.reduce((acc, curr) => {
    acc[curr.item_code] = { price: curr.amount, quantity: curr.qty };
    return acc;
  }, {});
  return res
}

