import { create, } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type AuthStore = {
    otplessUserData: any
    loginWithOTPLessMutateData: any
    userData: any;
    token: string | null;
    setToken: (token: string | null) => void;
    setUserData: (userData: string | null) => void; // Changed any to string | null
    resetAuthStore: () => void;
    setOtplessUserData: (otplessUserData: any) => void;
    setLoginWithOTPLessMutateData: (loginWithOTPLessMutateData: any) => void;
    isItOtpless: boolean 
    setIsItOtpless: (value: boolean) => void
};

const useAuthStore = create(
    persist<AuthStore>(
        (set: any) => ({
            userData: null,
            token: null,
            otplessUserData: {},
            loginWithOTPLessMutateData: {},
            setOtplessUserData: (newData) => set({ otplessUserData: newData }),
            setLoginWithOTPLessMutateData: (newData) => set({ loginWithOTPLessMutateData: newData }),
            setToken: (token: string | null) => set(() => ({ token })),
            setUserData: (userData: string | null) => set(() => ({ userData })),
            resetAuthStore: () => set(() => ({ userData: null, token: null })),
            isItOtpless: false,
            setIsItOtpless: (value: boolean) => set({ isItOtpless: value }),
        }),
        {
            name: 'auth-store',
            storage: createJSONStorage(() => localStorage)
        }
    )
);

export default useAuthStore;