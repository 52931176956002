import useAuthStore from "@/lib/store/useAuthStore";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";

const useLogout = () => {
    const { resetAuthStore } = useAuthStore(state => state);
    return useMutation(["logout"], async (payload: string) => {

        const resp = await axios.post(`${`${process.env.NEXT_PUBLIC_API_URL}/api/method/logout`}`);
        if (resp.status === 200) {
            localStorage.removeItem('user');
            localStorage.removeItem('mobile_no');
            localStorage.removeItem('token');
            localStorage.removeItem('email');
            resetAuthStore()
        }
        return resp.data;
    });


}


export default useLogout