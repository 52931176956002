import { create } from "zustand";
type TNavStore = {
  burgerState: boolean;
  setBurgerState: () => void;
};
const useNavStore = create<TNavStore>((set) => ({
  burgerState: false,
  setBurgerState: () =>
    set((state) => {
      return { burgerState: !state.burgerState };
    }),
}));
export default useNavStore;
