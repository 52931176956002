import { extendTheme } from '@chakra-ui/react';
import { metropolis } from './font';

const theme = extendTheme({
  fonts: {
    metropolis: metropolis.style.fontFamily,
  },
  colors: {
    brand: {
      primary: "#ff385c",
      primaryHover: "#ff2950" 
    },
  },
  components: {
    Checkbox: {
        baseStyle: {
            control: {
                _checked: {
                    bg: "brand.primary",
                    borderColor: "brand.primary",
                    _hover: {
                        bg: "brand.primaryHover",
                        borderColor: "brand.primaryHover",   
                    }
                },
                 
            },
        }
    },
    Tabs: {
      baseStyle: {
        tab: {
          color: "black.400",
          borderColor: "black.400",
        }
      },     
    }
  },
});

export default theme;
