"use client";
import useNavStore from "@/lib/store/newStore/useNavStore";
import React from "react";

const Hamburger = ({
  className,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) => {
  const burgerState = useNavStore((state) => state.burgerState);
  const navControl = useNavStore((state) => state.setBurgerState);
  const handleClick = () => {
    navControl();
    console.log(burgerState);
  };
  return (
    <div
      className="space-y-[2px] hover:cursor-pointer"
      {...props}
      onClick={handleClick}
    >
      <div className="w-6 h-1 rounded-xl bg-white"></div>
      <div className="w-6 h-1 rounded-xl  bg-white"></div>
      <div className="w-6 h-1 rounded-xl  bg-white"></div>
    </div>
  );
};

export default Hamburger;
